import React from 'react';

function App() {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-400 to-purple-500">
      <h1 className="text-6xl font-bold text-white" style={{ fontFamily: "'Fredoka One', cursive" }}>
        Coming Soon!
      </h1>
    </div>
  );
}

export default App;